<template>
  <div class="auth-main viewContainer" >
    <v-container class="auth-container pa-4">
      <div>
        <v-tabs
          v-model="tab"
          :color="($store.state.app.dark) ? 'white' : 'black'"
          class="content-tabs"
          background-color="transparent"
          fixed-tabs
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#tab-0" class="no-caps" :ripple="false">
            {{ $t('about_tab1') }}
            <!-- <v-icon size="24">mdi-account-outline</v-icon> -->
          </v-tab>
          <v-tab href="#tab-1" class="no-caps" :ripple="false">
            {{ $t('about_tab2') }}
            <!-- <v-icon size="24">mdi-account-tie-outline</v-icon> -->
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" style="background-color: transparent;" class="mt-8">
          <v-tab-item value="tab-0">
            <v-card class="content-tabs-card px-8 py-12 rounded">
              <h1 class="text-display-2 font-weight-medium mb-4">{{ $t('about_tab1_title') }}</h1>
              <p class="mb-4 text-subtitle-1 text--secondary">{{ $t('about_tab1_desc1') }}</p>
              <p class="mb-4 text-subtitle-1 text--secondary">{{ $t('about_tab1_desc2') }}</p>
              <p class="mb-4 text-subtitle-1 text--secondary">{{ $t('about_tab1_desc3') }}</p>
              <p class="mb-0 text-subtitle-1 text--secondary">{{ $t('about_tab1_desc4') }}</p>
              <v-btn v-if="!$auth.check()" class="mt-8 no-caps" color="primary" rounded x-large depressed :to="{ name: 'register' }">{{ $t('get_started_now') }}</v-btn>
            </v-card>
          </v-tab-item>
          <v-tab-item value="tab-1">
            <v-card class="content-tabs-card px-8 py-12 rounded">
              <h1 class="text-display-2 font-weight-medium mb-4">{{ $t('about_tab2_title') }}</h1>
              <p class="mb-4 text-subtitle-1 text--secondary">{{ $t('about_tab2_desc1') }}</p>
              <p class="mb-4 text-subtitle-1 text--secondary">{{ $t('about_tab2_desc2') }}</p>
              <p class="mb-4 text-subtitle-1 text--secondary">{{ $t('about_tab2_desc3') }}</p>
              <p class="mb-4 text-h6">{{ $t('about_tab2_title2') }}</p>
              <p class="mb-4 text-subtitle-1 text--secondary">{{ $t('about_tab2_desc4') }}</p>
              <div class="pl-4" style="border-left: 4px solid #ccc;">
                <p class="mb-4 text-subtitle-1 font-weight-medium">{{ $t('about_tab2_desc5') }}</p>
                <p class="mb-0 text-subtitle-1 font-weight-medium">{{ $t('about_tab2_desc6') }}</p>
              </div>
              <v-btn v-if="!$auth.check()" class="mt-8 no-caps" color="primary" rounded x-large depressed :to="{ name: 'register-business' }">{{ $t('get_started_now') }}</v-btn>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-container>
  </div>
</template>
<script>
export default {
  data: () => ({
    tab: null,
    tabImg: 'about:blank',
    tabImgHeight: null,
    tabImgDefaultHeight: 780,
    tabImgAspectRation: 1.7778,
    tabImgs: []
  }),
  mounted () {
    document.title = this.$t('about_page_title')

  },
  methods: {
  },
  watch: {
    tab: function (val) {
      if (window.innerWidth > 760) {
        let index = val.split('-')
        this.tabImg = this.tabImgs[index[1]]
        this.tabImgHeight = this.tabImgDefaultHeight
        this.tabImgAspectRation = 1.7778
      }
    }
  }
};
</script>